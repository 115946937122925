import {getAxiosInstance} from "src/server/utils/getAxiosInstance";
import {getEnv} from "src/server/utils/getEnv";
import {filterEnv} from "src/server/utils/filterEnv";
import * as ShopsApi from "src/core/api/shops";
import ShopFullSettings from "src/core/common/models/shopFullSettings";
import * as Sentry from "@sentry/nextjs";
import makeSite from "../../core/common/tymberFactory/makeSite";
import {getTheme} from "src/core/sites/utils";
import {isClient} from "src/server/utils/isClient";
import {isKiosk} from "src/server/utils/isKiosk";
import TymberAPIConfig from "src/core/api/config";
import {makeSitesRepository} from "src/core/common/tymberFactory";
import {getSiteInCluster} from "src/server/clusters";

export const getSitePreloadData = async (shop, host) => {
  try {
    const siteConfig = getSiteInCluster(host);

    const site = await getSite(shop, host);

    return {
      site: site,
      shop,
      host,
      siteGroupName: siteConfig.name,
      metadata: {
        siteImage: site.site.banner_url || site.site.logo_url,
        siteTitle: site.site.html_title,
        siteDescription: site.site.meta_description,
        favicon: site.site.favicon_url,
      },
    };
  } catch (e) {
    console.error("Error fetching home data", e);
    Sentry.setTag("Home Page Prefetch Error", true);
    Sentry.captureException(e);
    return {};
  }
};

export async function getSite(shop, host) {
  if (!shop && !isKiosk(shop, host)) {
    return loadSite(getEnv(shop, host));
  }

  const axiosInstance = getAxiosInstance(shop, host);
  const env = getEnv(shop, host);
  const siteId = env.REACT_APP_TYMBER_ECOMMERCE_ID;

  try {
    const [data, theme] = await getData(axiosInstance);

    const fullSettings = new ShopFullSettings(data);

    Sentry.init({
      dsn: env.REACT_APP_SENTRY_DSN,
    });

    const groupSettings = fullSettings.groupSettings.attributes;
    const settings = fullSettings.getShopSettingsById(siteId).attributes;
    const siteSettings = fullSettings.getShopById(siteId).getSite();

    const site = makeSite(siteSettings, settings, groupSettings);
    return {
      id: siteId,
      settings: site.settingsObject,
      groupSettings: site.groupSettingsObject,
      site: site.siteObject,
      theme: theme,
      env: filterEnv(env),
    };
  } catch (e) {
    console.error("Error fetching site data", e);
    return {
      id: siteId,
      env: filterEnv(env),
    };
  }
}

async function getData(axiosInstance) {
  return new Promise(async (resolve, reject) => {
    const timeout = setTimeout(
      () => {
        if (!isClient) {
          Sentry.setTag("Prefetch Timeout", true);
          Sentry.captureException(new Error("Timeout during get initial props"));
        }
        reject();
      },
      isClient ? 15000 : 5000
    );

    Promise.all([ShopsApi.fullSettings(axiosInstance), getThemeVariables(axiosInstance)])
      .then(resolve)
      .catch(reject)
      .finally(() => {
        clearTimeout(timeout);
      });
  });
}

async function loadSite(envObj, forceInitialization = false) {
  if (!TymberAPIConfig.initialized || forceInitialization) {
    TymberAPIConfig.init({
      storeId: envObj.REACT_APP_TYMBER_ECOMMERCE_ID,
      kioskId: envObj.REACT_APP_TYMBER_KIOSK_ID,
      baseUrl: envObj.REACT_APP_TYMBER_ECOMMERCE_HOST,
    });
  }

  const sitesRepository = makeSitesRepository();
  const site = await sitesRepository.get();
  const theme = await getTheme();

  let filteredEnv = filterEnv(envObj);
  return {
    id: envObj.REACT_APP_TYMBER_ECOMMERCE_ID,
    settings: site.settingsObject,
    groupSettings: site.groupSettingsObject,
    site: site.siteObject,
    theme: theme,

    env: filteredEnv,
  };
}

async function getThemeVariables(axiosInstance) {
  return getTheme(axiosInstance);
}
